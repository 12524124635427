import d3csv from "bundle-text:../nc_dashboard_data/incidence_germany.csv";
import Papa from "papaparse";

//parsing parameters
const { data: ger_incidences } = Papa.parse(d3csv, {
  header: true,
  dynamicTyping: true,
  fastMode: true,
  skipEmptyLines: "greedy",
});
//selection of data columns and types that will be parsed from the country level incidences
export default ger_incidences as {
  day: string;
  infections: number;
  r_value: number;
  infections_change: number;
  avg_incidence: number;
}[];
