import * as Plotly from "plotly.js-dist-min";
import ger_incidences from "./incidence-germany";
import bl_incidences from "./incidence-laender";
import cx_data from "./contact-laender";
import bl_geojson from "../data/bundeslaender.geojson";
import {
  two,
  ampel,
  contact_color,
  config,
  bundeslaender,
  day_number,
  toUNIXTime,
  get_zoom,
  sleep,
  smooth,
} from "./helpers";

//first CX datapoint is 41 after first incidence data point, we need offset
const start_date_offset = 41; 
// all the html elements involved (sliders, date picker, buttons,...)
const r_value_date_picker = document.getElementById(
  "r-value-date-picker"
) as HTMLInputElement;
const r_value_date_slider = document.getElementById(
  "r-value-date-slider"
) as HTMLInputElement;
const contact_date_picker = document.getElementById(
  "contact-date-picker"
) as HTMLInputElement;
const contact_date_slider = document.getElementById(
  "contact-date-slider"
) as HTMLInputElement;
const start_button = document.getElementById(
  "dashboard-start-button"
) as HTMLButtonElement;
const date_offset = document.getElementById("date-offset") as HTMLInputElement;
const link_button = document.getElementById("link-button") as HTMLButtonElement;
const r_smooth = document.getElementById("r-smooth") as HTMLInputElement;
const cx_smooth = document.getElementById("cx-smooth") as HTMLInputElement;

//boolean that prevents draw functions calling each other in loop
let link_draw_flag = false;
//boolean for the link between charts
let link_bool = true;
//link icons
const link_svg = document.getElementById("link-svg") as HTMLImageElement;
const unlink_svg = document.getElementById("unlink-svg") as HTMLImageElement;
//behaviour of link button
link_button.onclick = () => {
  if (link_bool) {
    unlink_svg.hidden = false;
    link_svg.hidden = true;
    link_button.title = "Datumsauswahl verknüpfen";
  } else {
    unlink_svg.hidden = true;
    link_svg.hidden = false;
    link_button.title = "Datumsauswahl trennen";
  }
  link_bool = !link_bool;
};

//find unique days in incidence_data as UNIX-Time
let i_unique_days = bl_incidences.map((i) => toUNIXTime(i.day));
i_unique_days.filter((v, i, a) => a.indexOf(v) === i);

r_value_date_slider.max = Math.max(...i_unique_days).toString();
r_value_date_slider.min = Math.min(...i_unique_days).toString();
r_value_date_slider.value = r_value_date_slider.max;
r_value_date_picker.value = new Date(r_value_date_slider.valueAsNumber)
  .toISOString()
  .split("T")[0];
 
//find unique days in cx_data as UNIX-Time 
let cx_unique_days = cx_data.map((i) => toUNIXTime(i.day));
cx_unique_days.filter((v, i, a) => a.indexOf(v) === i);
contact_date_slider.max = Math.max(...i_unique_days).toString();
contact_date_slider.min = Math.min(...i_unique_days).toString();
contact_date_slider.value = contact_date_slider.max;
contact_date_picker.value = new Date(contact_date_slider.valueAsNumber)
  .toISOString()
  .split("T")[0];

let cx_map_layout = {
  title: { text: "Kontaktindex 7-Tage Mittelwert", pad: { b: 0, l: 0, r: 0, t: 0 } },
  mapbox: {
    style: "carto-positron",
    center: { lat: 51.3, lon: 10.45 },
    zoom: get_zoom(),
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 30,
  },
  annotations: [],
  autosize: true,
  uirevision: true,
  showlegend: false,
};

let r_value_map_layout = {
  title: { text: "7-Tage R-Wert", pad: { b: 0, l: 0, r: 0, t: 0 } },
  mapbox: {
    style: "carto-positron",
    center: { lat: 51.3, lon: 10.45 },
    zoom: get_zoom(),
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 30,
  },
  annotations: [],
  autosize: true,
  uirevision: true,
  showlegend: false,
};

//line charts
//the dates from the cx data set
let contact_dates = cx_data.map((i) => new Date(i.day));
//the dates from the germany incidence data set
let incidence_dates = ger_incidences.map((i) => new Date(i.day));
let cx_select_bool = false;
let r_select_bool = false;
let last_r_location = 0;
let last_cx_location = 0;

//function that is responsible for the cx map
function contact_draw(day: string) {
  //defines the behavior if linked and not yet synced with r map
  if (link_bool && link_draw_flag) {
    link_draw_flag = false;
    const offset_day = new Date(
      toUNIXTime(day) + date_offset.valueAsNumber * day_number
    )
      .toISOString()
      .split("T")[0];
    r_value_draw(offset_day);
    r_value_date_picker.value = offset_day;
  }
  //updates the date slider
  contact_date_slider.value = toUNIXTime(day).toString();
  //derives currentday
  let currentday =
    (Number(contact_date_slider.value) - Number(contact_date_slider.min)) /
      day_number -
    start_date_offset;
  let bl_contact_data;
  const bl_one_day_contact = cx_data.filter((i) => i.day === day);

  //check for missing data
  if (bl_one_day_contact.length > 0) {
    bl_contact_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: bl_one_day_contact.map((i) => two(i.BID.toString())),
      z: bl_one_day_contact.map((i) => i.avg_cx),
      colorscale: contact_color,
      zmin: "0.0",
      zmax: "500",
      geojson: bl_geojson,
      hovertemplate:
        "%{text}" + "<br>Kontaktindex Mittelwert: %{z:.2f}<extra></extra>",
      text: bundeslaender,
    };
    cx_map_layout.annotations = [];
  } //missing data
  else {
    bl_contact_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: ["01"],
      z: [null],
      colorscale: contact_color,
      zmin: "0.0",
      zmax: "500",
      geojson: bl_geojson,
      hovertemplate:
        "%{text}" + "<br>Kontaktindex Mittelwert: %{z:.2f}<extra></extra>",
      text: bundeslaender,
    };
    //add annotation for missing data
    cx_map_layout.annotations = [
      {
        text: "Für dieses Datum liegen <br> leider noch keine Daten vor.",
        font: { family: "Montserrat", size: 20 },
        bordercolor: "#000000",
        borderwidth: 2,
        borderpad: 4,
        bgcolor: "#ffffff",
        showarrow: false,
        x: 0.5,
        y: 0.5,
        xref: "paper",
        yref: "paper",
      },
    ];
  }
  //draw map and add listeners
  Plotly.react("contact-map", [bl_contact_data], cx_map_layout, config).then(
    (pl) => {
      pl.removeAllListeners();
      pl.on("plotly_hover", function () {
        document.body.style.cursor = "pointer";
      });
      pl.on("plotly_unhover", function () {
        document.body.style.cursor = "default";
      });
      pl.on("plotly_click", (d) => {
        let pt = (d.points || [])[0];
        //get clicked locations
        let cx_detail_selector = pt.location;
        if (pt.location != last_cx_location) {
          cx_select_bool = false;
        }
        if (cx_select_bool) {
          //deselected
          Plotly.react("contact-map", [bl_contact_data], cx_map_layout, config);
          cx_select_bool = false;
        } else {
          //selected
          let number = bl_one_day_contact
            .filter((i) => i.BID == pt.location)
            .map((i) => i.avg_cx);
          let bl_lonely_contact_data = {
            type: "choroplethmapbox",
            featureidkey: "properties.LAN_RS",
            locations: [pt.location],
            z: number,
            colorscale: contact_color,
            hovertemplate:
              "%{text}" +
              "<br>Kontaktindex Mittelwert: %{z:.2f}<extra></extra>",
            text: [bundeslaender[Number(pt.location) - 1]],
            zmin: "0.0",
            zmax: "500",
            geojson: bl_geojson,
            marker: { line: { width: 2, color: "white" } },
          };
          //draw map with highlighting
          Plotly.react(
            "contact-map",
            [bl_contact_data, bl_lonely_contact_data],
            cx_map_layout,
            config
          );
          cx_select_bool = true;
        }
        last_cx_location = pt.location;
        cx_chart_draw(cx_detail_selector, currentday);
      });
    }
  );
  if (cx_select_bool) {
    //check selection for date change
    let number = bl_one_day_contact
      .filter((i) => i.BID == cx_last_detail_selector)
      .map((i) => i.avg_cx);
    let bl_lonely_contact_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: [cx_last_detail_selector],
      z: number,
      colorscale: contact_color,
      hovertemplate:
        "%{text}" + "<br>Kontaktindex Mittelwert: %{z:.2f}<extra></extra>",
      text: [bundeslaender[Number(cx_last_detail_selector) - 1]],
      zmin: "0.0",
      zmax: "500",
      geojson: bl_geojson,
      marker: { line: { width: 2, color: "white" } },
    };
    Plotly.react(
      "contact-map",
      [bl_contact_data, bl_lonely_contact_data],
      cx_map_layout,
      config
    );
  }
  cx_chart_draw(cx_last_detail_selector, currentday, false);
}

let cx_chart_layout = {
  autosize: true,
  uirevision: true,
  yaxis: {
    rangemode: "tozero",
    automargin: true,
    range: [0, 200],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [
      contact_dates[0],
      new Date(
        toUNIXTime(contact_dates[contact_dates.length - 1].toString()) +
          10 * day_number
      ),
    ],
  },
  hovermode: "x unified",
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 35,
  },
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: "Kontaktindex 7-Tage Mittelwert in Deutschland",
};
let cx_last_detail_selector = "";
//function responsible for cx chart
function cx_chart_draw(cx_detail_selector, currentday, draw_different = true) {
  let cx_avg_data;
  let cx_marker;
  let cx_line;
  let cx_lower_data;
  let cx_upper_data;
  let cx_upper_line;
  let cx_lower_line;
  let contact_data;
  let cx_avg_line;
  if (
    (draw_different && cx_detail_selector == cx_last_detail_selector) ||
    cx_detail_selector == ""
  ) {
    contact_data = cx_data
      .filter((i) => i.BID === "Deutschland")
      .map((i) => i.cx);
    cx_lower_data = cx_data
      .filter((i) => i.BID === "Deutschland")
      .map((i) => i.cx_lower);
    cx_upper_data = cx_data
      .filter((i) => i.BID === "Deutschland")
      .map((i) => i.cx_upper);
    cx_avg_data = cx_data
      .filter((i) => i.BID === "Deutschland")
      .map((i) => i.avg_cx);
    cx_chart_layout.title = "Kontaktindex 7-Tage Mittelwert in Deutschland";
    cx_last_detail_selector = "";
    //we want the yaxis range to be 10% higher than our max value excluding precovid values
    cx_chart_layout.yaxis.range = [0, Math.max(...cx_avg_data.slice(31)) * 1.1];
    if (cx_smooth.valueAsNumber > 0) {
      cx_avg_data = smooth(cx_avg_data, cx_smooth.valueAsNumber);
    }
    //daily cx line
    cx_line = {
      x: contact_dates,
      y: contact_data,
      type: "scatter",
      opacity: 0,
      showlegend: false,
      name: "CX",
      marker: { color: "darkorange" },
    };
    // 7 day avg cx line
    cx_avg_line = {
      x: contact_dates,
      y: cx_avg_data,
      showlegend: false,
      type: "scatter",
      name: "CX-Mittelwert",
      line: { color: "rgb(41,97,98)", shape: "spline", smoothing: 0.5 },
    };
    //lower limit of confidence intervall line
    cx_lower_line = {
      x: contact_dates,
      y: cx_lower_data,
      showlegend: false,
      type: "scatter",
      name: "CX-Lower",
      fill: "tonexty",
      fillcolor: "rgba(41,97,98,0.25)",
      line: { color: "rgb(41,97,98)", width: 0 },
      hoverinfo: "skip",
    };
    //upper limit of confidence intervall line 
    cx_upper_line = {
      x: contact_dates,
      y: cx_upper_data,
      showlegend: false,
      type: "scatter",
      name: "CX-Upper",
      line: { color: "rgb(41,97,98)", width: 0 },
      hoverinfo: "skip",
    };
    //dot that marks selected day
    cx_marker = {
      x: [incidence_dates[currentday + start_date_offset]],
      y: [cx_avg_data[currentday - 1]],
      type: "scatter",
      yaxis: "y",
      mode: "markers",
      marker: { symbol: "circle", color: "red", size: 10 },
      showlegend: false,
      hoverinfo: "skip",
    };
    //smoothing of cx chart over selected week
    if (cx_smooth.valueAsNumber > 0) {
      Plotly.react(
        "cx-chart",
        [cx_line, cx_avg_line, cx_marker],
        cx_chart_layout,
        config
      );
    } else {
      Plotly.react(
        "cx-chart",
        [cx_upper_line, cx_lower_line, cx_line, cx_avg_line, cx_marker],
        cx_chart_layout,
        config
      );
    }
  } else {
    //chart on state level
    contact_data = cx_data
      .filter((i) => i.BID == cx_detail_selector)
      .map((i) => i.cx);
    cx_avg_data = cx_data
      .filter((i) => i.BID == cx_detail_selector)
      .map((i) => i.avg_cx);
    cx_chart_layout.title =
      "Kontaktindex 7-Tage Mittelwert in " +
      bundeslaender[Number(cx_detail_selector) - 1];
    cx_last_detail_selector = cx_detail_selector;
    cx_chart_layout.yaxis.range = [0, Math.max(...cx_avg_data.slice(31)) * 1.1];
    cx_line = {
      x: contact_dates,
      y: contact_data,
      type: "scatter",
      opacity: 0,
      showlegend: false,
      name: "CX",
      marker: { color: "darkorange" },
    };
    if (cx_smooth.valueAsNumber > 0) {
      cx_avg_data = smooth(cx_avg_data, cx_smooth.valueAsNumber);
    }
    cx_avg_line = {
      x: contact_dates,
      y: cx_avg_data,
      showlegend: false,
      type: "scatter",
      name: "CX-Mittelwert",
      fill: "tozeroy",
      fillcolor: "rgba(41,97,98,0.25)",
      line: { color: "rgb(41,97,98)", shape: "spline", smoothing: 0.5 },
    };
    cx_marker = {
      x: [incidence_dates[currentday + start_date_offset]],
      y: [cx_avg_data[currentday - 1]],
      type: "scatter",
      yaxis: "y",
      mode: "markers",
      marker: { symbol: "circle", color: "red", size: 10 },
      showlegend: false,
      hoverinfo: "skip",
    };
    Plotly.react(
      "cx-chart",
      [cx_line, cx_avg_line, cx_marker],
      cx_chart_layout,
      config
    );
  }
}

//function responsible for r map
function r_value_draw(day: string) {
  //defines behaviour if maps are linked or not synced
  if (link_bool && link_draw_flag) {
    link_draw_flag = false;
    const offset_day = new Date(
      toUNIXTime(day) - date_offset.valueAsNumber * day_number
    )
      .toISOString()
      .split("T")[0];
    contact_draw(offset_day);
    contact_date_picker.value = offset_day;
  }
  //update slider
  r_value_date_slider.value = toUNIXTime(day).toString();
  //get current day as offset from first day
  let currentday =
    (Number(r_value_date_slider.value) - Number(r_value_date_slider.min)) /
    day_number;
  const bl_one_day_r_value = bl_incidences.filter((i) => i.day === day);

  let bl_r_value_data;
  if (bl_one_day_r_value.length > 0) {
    bl_r_value_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: bl_one_day_r_value.map((i) => two(i.BID.toString())),
      z: bl_one_day_r_value.map((i) => i.r_value),
      colorscale: ampel,
      hovertemplate: "%{text}" + "<br>R-Wert: %{z:.2f}<extra></extra>",
      text: bundeslaender,
      zmin: "0.0",
      zmax: "2",
      geojson: bl_geojson,
    };
    r_value_map_layout.annotations = [];
  } else {
    //missing dates
    bl_r_value_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: ["00"],
      z: [null],
      colorscale: ampel,
      hovertemplate: "%{text}" + "<br>R-Wert: %{z:.2f}<extra></extra>",
      text: bundeslaender,
      zmin: "0.0",
      zmax: "2",
      geojson: bl_geojson,
    };
    r_value_map_layout.annotations = [
      {
        text: "Für dieses Datum liegen <br> leider noch keine Daten vor.",
        font: { family: "Montserrat", size: 20 },
        bordercolor: "#000000",
        borderwidth: 2,
        borderpad: 4,
        bgcolor: "#ffffff",
        showarrow: false,
        x: 0.5,
        y: 0.5,
        xref: "paper",
        yref: "paper",
      },
    ];
  }

  //draw map and add listeners
  Plotly.react(
    "r-value-map",
    [bl_r_value_data],
    r_value_map_layout,
    config
  ).then((gd) => {
    gd.removeAllListeners();
    gd.on("plotly_hover", function () {
      document.body.style.cursor = "pointer";
    });
    gd.on("plotly_unhover", function () {
      document.body.style.cursor = "default";
    });
    gd.on("plotly_click", (d) => {
      let pt = (d.points || [])[0];
      let r_value_detail_selector = pt.location;
      //get clicked location
      if (pt.location != last_r_location) {
        r_select_bool = false;
      }
      if (r_select_bool) {
        //deselected
        Plotly.react(
          "r-value-map",
          [bl_r_value_data],
          r_value_map_layout,
          config
        );
        r_select_bool = false;
      } else {
        //selected
        let number = bl_one_day_r_value
          .filter((i) => i.BID == pt.location)
          .map((i) => i.r_value);
        let bl_lonely_r_value_data = {
          type: "choroplethmapbox",
          featureidkey: "properties.LAN_RS",
          locations: [pt.location],
          z: number,
          colorscale: ampel,
          hovertemplate: "%{text}" + "<br>R-Wert: %{z:.2f}<extra></extra>",
          text: [bundeslaender[Number(pt.location) - 1]],
          zmin: "0.0",
          zmax: "2",
          geojson: bl_geojson,
          marker: { line: { width: 2, color: "white" } },
        };
        //draw map with highlighting
        Plotly.react(
          "r-value-map",
          [bl_r_value_data, bl_lonely_r_value_data],
          r_value_map_layout,
          config
        );
        r_select_bool = true;
      }
      last_r_location = pt.location;
      r_value_chart_draw(r_value_detail_selector, currentday);
    });

    //check selection for date change
    if (r_select_bool) {
      let number = bl_one_day_r_value
        .filter((i) => i.BID == r_value_last_detail_selector)
        .map((i) => i.r_value);
      let bl_lonely_r_value_data = {
        type: "choroplethmapbox",
        featureidkey: "properties.LAN_RS",
        locations: [r_value_last_detail_selector],
        z: number,
        colorscale: ampel,
        hovertemplate: "%{text}" + "<br>R-Wert: %{z:.2f}<extra></extra>",
        text: [bundeslaender[Number(r_value_last_detail_selector) - 1]],
        zmin: "0.0",
        zmax: "2",
        geojson: bl_geojson,
        marker: { line: { width: 2, color: "white" } },
      };
      Plotly.react(
        "r-value-map",
        [bl_r_value_data, bl_lonely_r_value_data],
        r_value_map_layout,
        config
      );
    }
  });
  r_value_chart_draw(r_value_last_detail_selector, currentday, false);
}
let r_value_last_detail_selector = "";
let r_value_layout = {
  yaxis: {
    title: { text: "R-Wert", standoff: 10 },
    rangemode: "tozero",
    automargin: true,
    range: [0.25, 1.75],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [
      incidence_dates[0],
      new Date(
        toUNIXTime(incidence_dates[incidence_dates.length - 1].toString()) +
          10 * day_number
      ),
    ],
  },
  
  title: "R-Wert in Deutschland",
  autosize: true,
  uirevision: true,
  hovermode: "x unified",
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 35,
  },
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
};

//function responsible for the r value chart
function r_value_chart_draw(
  r_value_detail_selector,
  currentday,
  //bool for keeping selection
  draw_different = true
) {
  let r_value_data;
  let r_value_marker;
  let r_value_chart_line;
  if (
    (draw_different &&
      r_value_detail_selector == r_value_last_detail_selector) ||
    r_value_detail_selector == ""
  ) {
    r_value_data = ger_incidences.map((i) => i.r_value);
    r_value_layout.title = "R-Wert in Deutschland";
    r_value_last_detail_selector = "";
  } else {
    //r value chart for selected state
    r_value_data = bl_incidences
      .filter((i) => i.BID == r_value_detail_selector)
      .map((i) => i.r_value);
    r_value_layout.title =
      "R-Wert in " + bundeslaender[Number(r_value_detail_selector) - 1];
    r_value_last_detail_selector = r_value_detail_selector;
  }
  //smoothing of r value based on select number as weeks
  if (r_smooth.valueAsNumber > 0) {
    r_value_data = smooth(r_value_data, r_smooth.valueAsNumber);
  }
  r_value_chart_line = {
    x: incidence_dates,
    y: r_value_data,
    showlegend: false,
    type: "scatter",
    name: "R-Wert",
    yaxis: "y",
    fill: "tozeroy",
    fillcolor: "rgba(252,108,5,0.25)",
    line: { color: "rgb(252,108,5)", shape: "spline" },
  };
  r_value_marker = {
    x: [incidence_dates[currentday]],
    y: [r_value_data[currentday]],
    type: "scatter",
    yaxis: "y",
    mode: "markers",
    marker: { symbol: "circle", color: "red", size: 10 },
    showlegend: false,
    hoverinfo: "skip",
  };
  Plotly.react(
    "r-value-chart",
    [r_value_chart_line, r_value_marker],
    r_value_layout,
    config
  );
}

//behaviour of date offset box
date_offset.onchange = () => {
  if (link_bool) {
    link_draw_flag = true;
    r_value_draw(r_value_date_picker.value);
  }
};

r_value_date_slider.onchange = () => {
  let day = new Date(r_value_date_slider.valueAsNumber)
    .toISOString()
    .split("T")[0];
  r_value_date_picker.value = day;
  link_draw_flag = true;
  r_value_draw(day);
};

r_value_date_picker.onchange = () => {
  link_draw_flag = true;
  r_value_draw(r_value_date_picker.value);
};

r_smooth.onchange = () => {
  r_value_draw(r_value_date_picker.value);
};

cx_smooth.onchange = () => {
  contact_draw(contact_date_picker.value);
};

contact_date_slider.onchange = () => {
  let day = new Date(contact_date_slider.valueAsNumber)
    .toISOString()
    .split("T")[0];
  contact_date_picker.value = day;
  link_draw_flag = true;
  contact_draw(day);
};

contact_date_picker.onchange = () => {
  link_draw_flag = true;
  contact_draw(contact_date_picker.value);
};

//draw for first time
cx_map_layout.mapbox.zoom = get_zoom();
r_value_map_layout.mapbox.zoom = get_zoom();
link_draw_flag = true;
//animation
let animate_bool = false;
async function animate() {
  console.log("clock");
  let r_start = r_value_date_slider.valueAsNumber;
  let r_stop = Number(r_value_date_slider.max);
  let cx_start = contact_date_slider.valueAsNumber;
  let cx_stop = Number(contact_date_slider.max);
  let cx_i = cx_start;
  for (let i = r_start; i <= r_stop && cx_i <= cx_stop; i = i + day_number) {
    if (animate_bool == false) {
      break;
    }
    let r_day = new Date(i).toISOString().split("T")[0];
    r_value_date_slider.value = r_day;
    r_value_date_picker.value = r_day;
    r_value_draw(r_day);
    let cx_day = new Date(cx_i).toISOString().split("T")[0];
    contact_date_picker.value = cx_day;
    contact_date_slider.value = cx_day;
    contact_draw(cx_day);
    cx_i = cx_i + day_number;
    await sleep(50);
  }
  start_button.innerText = "Animation starten";
  animate_bool = false;
}

start_button.onclick = () => {
  if (animate_bool) {
    start_button.innerText = "Animation starten";
    animate_bool = false;
  } else {
    start_button.innerText = "Animation stoppen";
    animate_bool = true;
    animate();
  }
};
r_value_draw(r_value_date_picker.value);
