//import all chart definitions and info_numbers
import "./contact_chart";
import "./cx_chart";
import "./paper_chart";
import "./incidence_board";
import "./dashboard";
import "./info_numbers";

const wrapper = document.getElementById("wrapper") as HTMLDivElement;

//button to get back to top
const jump_button = document.getElementById("jump-to-top") as HTMLButtonElement;
const dashboard_start = document.getElementById(
  "dashboard-start"
) as HTMLDivElement;

jump_button.onclick = () => {
  dashboard_start.scrollIntoView({
    behavior: "auto",
  });
};

//remove blur when loading done
wrapper.style.filter = "none";
