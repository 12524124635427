import cx_data from "./contact-laender";
import ger_incidences from "./incidence-germany";
import * as Plotly from "plotly.js-dist-min";
import { config } from "./helpers";

//draw cx-exp-chart:
//gets the html element corresponding to the "verschieben" button
const cx_2020_shift = document.getElementById(
  "cx-2020-shift"
) as HTMLButtonElement;
//get all dates from contact data
let contact_dates = cx_data.map((i) => new Date(i.day));
//get all dates from infection data
let incidence_dates = ger_incidences.map((i) => new Date(i.day));
//filter the 7day avg cx for germany
let cx_avg_data = cx_data
  .filter((i) => i.BID === "Deutschland")
  .map((i) => i.avg_cx);
//get the r value for germany
let ger_r_value_data = ger_incidences.map((i) => i.r_value);

//r value line
let r_value_line = {
  x: incidence_dates,
  y: ger_r_value_data,
  type: "scatter",
  name: "R-Wert",
  yaxis: "y2",
  xaxis: "x2",
  line: { color: "rgb(252,108,5)", shape: "spline" },
};
// 7 day avg cx line
let cx_2020_avg = {
  x: contact_dates,
  y: cx_avg_data,
  type: "scatter",
  name: "CX-Mittelwert",
  line: { color: "rgb(41,97,98)", shape: "spline", smoothing: 0.5 },
};

//predefine the offset from the r-value to the avg-cx value which defaults to 0
let r_value_offset = 0;
//set a boolean for the shift button that defaults to 0
let r_value_offset_bool = false;

//a function that is called in order to draw the chart
function draw_cx_2020_chart() {
  let cx_2020_chart_layout = {
    autosize: true,
    uirevision: true,
    yaxis: {
      automargin: true,
      range: [15, 100],
      showgrid: false,
    },
    yaxis2: {
      automargin: true,
      range: [0.6, 1.8],
      overlaying: "y",
      side: "right",
      showgrid: false,
    },
    xaxis: {
      automargin: true,
      autorange: false,
      //limit the dates to 2020
      range: [contact_dates[174], contact_dates[452]],
    },
    //the dates for the r-value are derived by applying an offset to the fixed contact index value dates
    xaxis2: {
      automargin: true,
      autorange: false,
      overlaying: "x",
      range: [
        contact_dates[174 + r_value_offset],
        contact_dates[452 + r_value_offset],
      ],
      side: "top",
    },
    hovermode: "x unified",
    margin: {
      l: 0,
      r: 0,
      b: 15,
      t: 45,
    },
    legend: {
      yanchor: "top",
      y: 0.99,
      xanchor: "left",
      x: 0,
    },
    title: {
      text: "Vergleich von R-Wert und Kontaktindex 7-Tage Mittelwert für 2020",
      y: 0.99,
    },
  };
  //draw the chart
  Plotly.react(
    "cx-2020-chart",
    [cx_2020_avg, r_value_line],
    cx_2020_chart_layout,
    config
  );
}
//intial call of function in order to draw the chart for the first time
draw_cx_2020_chart();

cx_2020_shift.onclick = () => {
  if (r_value_offset_bool) {
    r_value_offset_bool = false;
    r_value_offset = 0;
    draw_cx_2020_chart();
  } else {
    r_value_offset_bool = true;
    r_value_offset = 20;
    draw_cx_2020_chart();
  }
};
