import d3csv from "bundle-text:../nc_dashboard_data/incidence_laender.csv";
import Papa from "papaparse";

//parsing parameters
const { data: bl_incidences } = Papa.parse(d3csv, {
  header: true,
  dynamicTyping: true,
  fastMode: true,
  skipEmptyLines: "greedy",
});
//selection of data columns and types that will be parsed from the state infection data
export default bl_incidences as {
  day: string;
  avg_incidence: number;
  infections: number;
  r_value: number;
  BID: string;
}[];
