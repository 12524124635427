import cx_data from "./contact-laender";
import { day_number, toUNIXTime, toEUDate } from "./helpers";
import ger_incidences from "./incidence-germany";

// get all HTML elements needed
const contact_change_number = document.getElementById(
  "contact-change-number"
) as HTMLSpanElement;
const incidence_number = document.getElementById(
  "incidence-number"
) as HTMLSpanElement;
const infections_change_number = document.getElementById(
  "infections-change-number"
) as HTMLSpanElement;
const infections_number = document.getElementById(
  "infections-number"
) as HTMLSpanElement;

//get all spans for data pull-in date
const r_date = document.getElementById("r-date") as HTMLSpanElement;
const cx_date = document.getElementById("cx-date") as HTMLSpanElement;
const r_date2 = document.getElementById("r-date2") as HTMLSpanElement;
const r_date3 = document.getElementById("r-date3") as HTMLSpanElement;
const cx_date2 = document.getElementById("cx-date2") as HTMLSpanElement;

//set info numbers and colours
if (cx_data[cx_data.length - 1].cx_change - 1 > 0) {
  contact_change_number.innerText =
    "+" + ((cx_data[cx_data.length - 1].cx_change - 1) * 100).toFixed(1) + "%";
} else {
  contact_change_number.innerText =
    ((cx_data[cx_data.length - 1].cx_change - 1) * 100).toFixed(1) + "%";
}
incidence_number.innerText =
  ger_incidences[ger_incidences.length - 1].avg_incidence.toFixed(1);
if (ger_incidences[ger_incidences.length - 1].infections_change - 1 > 0) {
  infections_change_number.innerText =
    "+" +
    (
      (ger_incidences[ger_incidences.length - 1].infections_change - 1) *
      100
    ).toFixed(1) +
    "%";
  infections_change_number.style.color = "orange";
  if (ger_incidences[ger_incidences.length - 1].infections_change - 1 > 0.05) {
    infections_change_number.style.color = "rgb(255, 51, 0)";
  }
} else {
  infections_change_number.innerText =
    (
      (ger_incidences[ger_incidences.length - 1].infections_change - 1) *
      100
    ).toFixed(1) + "%";
  infections_change_number.style.color = "rgb(0, 182, 0)";
}
//set the daily infection number under incidence_board
infections_number.innerText =
  ger_incidences[ger_incidences.length - 1].infections.toString() +
  " Neuinfektionen";

//set the data off date texts
let r_value_date = toEUDate(
  new Date(
    toUNIXTime(ger_incidences[ger_incidences.length - 1].day)).toString()
);
let cx_value_date = toEUDate(cx_data[cx_data.length - 1].day.toString());
r_date.innerText = r_value_date;
r_date2.innerText = r_value_date;
r_value_date = toEUDate(
  new Date(
    toUNIXTime(ger_incidences[ger_incidences.length - 1].day) + day_number
  ).toString()
);
r_date3.innerText = r_value_date;
cx_date.innerText = cx_value_date;
cx_date2.innerText = cx_value_date;
