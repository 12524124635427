import cx_data from "./contact-laender";
import * as Plotly from "plotly.js-dist-min";
import { config} from "./helpers";

//draw the standalone cx chart for 2020:
//filter out all the dates from the contact related data set
let contact_dates = cx_data.map((i) => new Date(i.day));

//get the daily contact index for only Germany
let contact_data = cx_data
  .filter((i) => i.BID === "Deutschland")
  .map((i) => i.cx).slice(154,453);

//get the 7 day average contact index for Germany
let cx_avg_data = cx_data
  .filter((i) => i.BID === "Deutschland")
  .map((i) => i.avg_cx);

//daily contact line
let cx = {
  x: contact_dates,
  y: contact_data,
  type: "scatter",
  opacity: 0,
  showlegend: false,
  name: "CX",
  marker: { color: "darkorange" },
};

//contact index 7d avg line
let cx_avg = {
  x: contact_dates,
  y: cx_avg_data,
  showlegend: false,
  type: "scatter",
  name: "CX-Mittelwert",
  fill: "tozeroy",
  fillcolor: "rgba(41,97,98,0.25)",
  line: { color: "rgb(41,97,98)", shape: "spline", smoothing: 0.5 },
};

let cx_chart_layout = {
  autosize: true,
  uirevision: true,
  yaxis: {
    rangemode: "tozero",
    automargin: true,
    range: [7, 80],
  },
  //set the dates so it's only 2020
  xaxis: {
    automargin: true,
    autorange: false,
    range: [contact_dates[154], contact_dates[452]],
  },
  hovermode: "x unified",
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 35,
  },
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: "Kontaktindex 7-Tage Mittelwert in Deutschland",
};

//draw chart
Plotly.react("cx-exp-chart", [cx, cx_avg], cx_chart_layout, config);
