import cx_data from "./contact-laender";
import * as Plotly from "plotly.js-dist-min";
import { config } from "./helpers";

//get unique dates of cx_data
let contact_dates = cx_data.map((i) => new Date(i.day));
//filter for data of Germany and limit to 2020 data
let k7_data = cx_data.filter((i) => i.BID === "Deutschland").map((i) => i.k7);

let k_chart_layout = {
  autosize: true,
  uirevision: true,
  yaxis: {
    rangemode: "tozero",
    automargin: true,
    range: [7, Math.max(...k7_data) * 1.1],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [contact_dates[154], contact_dates[452]],
  },
  hovermode: "x unified",
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 35,
  },
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: "7-Tage Kontaktzahl in Deutschland",
};

//contact data line
let k7 = {
  x: contact_dates,
  y: k7_data,
  showlegend: false,
  type: "scatter",
  name: "7-Tage Kontaktzahl",
  fill: "tozeroy",
  fillcolor: "rgba(40,150,42,0.25)",
  line: { color: "rgb(40,150,42)", shape: "spline", smoothing: 0.5 },
};
//draw chart
Plotly.react("k-chart", [k7], k_chart_layout, config);
