import d3csv from "bundle-text:../nc_dashboard_data/contact_index.csv";
import Papa from "papaparse";
//parsing parameters
const { data: cx_data } = Papa.parse(d3csv, {
  header: true,
  dynamicTyping: true,
  fastMode: true,
  skipEmptyLines: "greedy",
});
//selection of data columns and types that will be parsed from the contact data
export default cx_data as {
  day: string;
  cx: number;
  cx_upper: number;
  cx_lower: number;
  avg_cx: number;
  cx_change: number;
  k7: number;
  BID: string;
}[];
