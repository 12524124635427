import * as Plotly from "plotly.js-dist-min";
import ger_incidences from "./incidence-germany";
import bl_incidences from "./incidence-laender";
import bl_geojson from "../data/bundeslaender.geojson";
import {
  two,
  get_zoom,
  config,
  colorscale,
  bundeslaender,
  day_number,
  toUNIXTime,
  sleep,
} from "./helpers";

//filter unique days
let incidence_dates = ger_incidences.map((i) => new Date(i.day));

//get needed HTML elements
const incidence_date_picker = document.getElementById(
  "incidence-date-picker"
) as HTMLInputElement;
const incidence_date_slider = document.getElementById(
  "incidence-date-slider"
) as HTMLInputElement;
const start_button = document.getElementById(
  "inc-start-button"
) as HTMLButtonElement;

//find unique days in incidence_data as UNIX-Time for sliders
let i_unique_days = bl_incidences.map((i) => toUNIXTime(i.day));
i_unique_days.filter((v, i, a) => a.indexOf(v) === i);

//set slider and date_picker default value and range
incidence_date_slider.max = Math.max(...i_unique_days).toString();
incidence_date_slider.min = Math.min(...i_unique_days).toString();
incidence_date_slider.value = incidence_date_slider.max;
incidence_date_picker.value = new Date(incidence_date_slider.valueAsNumber)
  .toISOString()
  .split("T")[0];

let incidence_map_layout = {
  title: { text: "7-Tage Inzidenz", pad: { b: 0, l: 0, r: 0, t: 0 } },
  mapbox: {
    style: "carto-positron",
    center: { lat: 51.3, lon: 10.45 },
    zoom: get_zoom(),
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 30,
  },
  annotations: [],
  autosize: true,
  uirevision: true,
  showlegend: false,
};

//set helper variables for keeping state selection highlighting
let select_bool = false;
let last_location = 0;

function incidence_draw(day: string) {
  //set slider value if to match date_picker
  incidence_date_slider.value = toUNIXTime(day).toString();

  //filter for selected days values
  const bl_one_day_incidence = bl_incidences.filter((i) => i.day === day);

  let bl_incidence_data;
  //check if data is missing
  if (bl_one_day_incidence.length > 0) {
    bl_incidence_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: bl_one_day_incidence.map((i) => two(i.BID.toString())),
      z: bl_one_day_incidence.map((i) => i.avg_incidence),
      colorscale: colorscale,
      hovertemplate: "%{text}" + "<br>Inzidenz: %{z:.2f}<extra></extra>",
      text: bundeslaender,
      zmin: "0.0",
      zmax: "1500",
      geojson: bl_geojson,
    };
    incidence_map_layout.annotations = [];
  } else {
    bl_incidence_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: ["01"],
      z: [null],
      colorscale: colorscale,
      hovertemplate: "%{text}" + "<br>Inzidenz: %{z:.2f}<extra></extra>",
      text: bundeslaender,
      zmin: "0.0",
      zmax: "1500",
      geojson: bl_geojson,
    };
    incidence_map_layout.annotations = [
      {
        text: "Für dieses Datum liegen <br> leider noch keine Daten vor.",
        font: { family: "Montserrat", size: 20 },
        bordercolor: "#000000",
        borderwidth: 2,
        borderpad: 4,
        bgcolor: "#ffffff",
        showarrow: false,
        x: 0.5,
        y: 0.5,
        xref: "paper",
        yref: "paper",
      },
    ];
  }
  
  //draw map and add listeners
  Plotly.react(
    "incidence-map",
    [bl_incidence_data],
    incidence_map_layout,
    config
  ).then((gd) => {
    gd.removeAllListeners();
    gd.on("plotly_hover", (d) => {
      document.body.style.cursor = "pointer";
    });
    gd.on("plotly_unhover", (d) => {
      document.body.style.cursor = "default";
    });
    gd.on("plotly_click", (d) => {
      let pt = (d.points || [])[0];
      //get clicked location
      let detail_selector = pt.location;
      if (pt.location != last_location) {
        select_bool = false;
      }
      if (select_bool) {
        //deselected
        Plotly.react(
          "incidence-map",
          [bl_incidence_data],
          incidence_map_layout,
          config
        );
        select_bool = false;
      } else {
        //selected
        let number = bl_one_day_incidence
          .filter((i) => i.BID == pt.location)
          .map((i) => i.avg_incidence);
        let bl_lonely_incidence_data = {
          type: "choroplethmapbox",
          featureidkey: "properties.LAN_RS",
          locations: [pt.location],
          z: number,
          colorscale: colorscale,
          hovertemplate: "%{text}" + "<br>Inzidenz: %{z:.2f}<extra></extra>",
          text: [bundeslaender[Number(pt.location) - 1]],
          zmin: "0.0",
          zmax: "1500",
          geojson: bl_geojson,
          marker: { line: { width: 2, color: "white" } },
        };
        //draw map with highlighting
        Plotly.react(
          "incidence-map",
          [bl_incidence_data, bl_lonely_incidence_data],
          incidence_map_layout,
          config
        );
        select_bool = true;
      }
      last_location = pt.location;
      detail_chart_draw(detail_selector);
    });
  });
  //check if selected for map draw on date_change
  if (select_bool) {
    let number = bl_one_day_incidence
      .filter((i) => i.BID == last_detail_selector)
      .map((i) => i.avg_incidence);
    let bl_lonely_incidence_data = {
      type: "choroplethmapbox",
      featureidkey: "properties.LAN_RS",
      locations: [last_detail_selector],
      z: number,
      colorscale: colorscale,
      hovertemplate: "%{text}" + "<br>Inzidenz: %{z:.2f}<extra></extra>",
      text: [bundeslaender[Number(last_detail_selector) - 1]],
      zmin: "0.0",
      zmax: "1500",
      geojson: bl_geojson,
      marker: { line: { width: 2, color: "white" } },
    };
    Plotly.react(
      "incidence-map",
      [bl_incidence_data, bl_lonely_incidence_data],
      incidence_map_layout,
      config
    );
  }
  detail_chart_draw(last_detail_selector, false);
}

//detail-charts
let last_detail_selector = "";
//incidence chart
let incidence_detail_layout = {
  yaxis: {
    title: { text: "Inzidenz", standoff: 10 },
    rangemode: "tozero",
    automargin: true,
    side: "right",
    range: [0, Math.max(...ger_incidences.map((i) => i.avg_incidence)) * 1.1],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [
      incidence_dates[0],
      new Date(
        toUNIXTime(incidence_dates[incidence_dates.length - 1].toString()) +
          5 * day_number
      ),
    ],
  },
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 20,
  },
  autosize: true,
  uirevision: true,
  hovermode: "x unified",
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: { text: "7-Tage Inzidenz in Deutschland", y: 0.97 },
};
//r_value chart
let r_value_detail_layout = {
  yaxis: {
    title: { text: "R-Wert", standoff: 10 },
    rangemode: "tozero",
    automargin: true,
    side: "right",
    range: [0.25, 1.75],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [
      incidence_dates[0],
      new Date(
        toUNIXTime(incidence_dates[incidence_dates.length - 1].toString()) +
          5 * day_number
      ),
    ],
  },
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 20,
  },
  autosize: true,
  uirevision: true,
  hovermode: "x unified",
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: { text: "R-Wert in Deutschland", y: 0.97 },
};
//infection_number chart
let infection_detail_layout = {
  yaxis: {
    title: { text: "Neuinfektionen", standoff: 10 },
    rangemode: "tozero",
    automargin: true,
    side: "right",

    range: [0, Math.max(...ger_incidences.map((i) => i.infections)) * 1.1],
  },
  xaxis: {
    automargin: true,
    autorange: false,
    range: [
      incidence_dates[0],
      new Date(
        toUNIXTime(incidence_dates[incidence_dates.length - 1].toString()) +
          5 * day_number
      ),
    ],
  },
  margin: {
    l: 0,
    r: 0,
    b: 15,
    t: 20,
  },
  autosize: true,
  uirevision: true,
  hovermode: "x unified",
  legend: {
    yanchor: "top",
    y: 0.99,
    xanchor: "left",
    x: 0,
  },
  title: { text: "Neuinfektionen in Deutschland", y: 0.97 },
};

function detail_chart_draw(detail_selector, draw_different = true) {
  let incidence_data;
  let infection_data;
  let r_value_detail_data;
  //calculate offset from first day for marker
  let detail_currentday =
    (Number(incidence_date_slider.value) - Number(incidence_date_slider.min)) /
    day_number;
  //check whether to draw germany or specific state
  //set titles and filter for selected data
  if (
    (draw_different && detail_selector == last_detail_selector) ||
    detail_selector == ""
  ) {
    infection_detail_layout.title.text = "Neuinfektionen in Deutschland";
    r_value_detail_data = ger_incidences.map((i) => i.r_value);
    r_value_detail_layout.title.text = "R-Wert in Deutschland";
    incidence_detail_layout.title.text = "7-Tage Inzidenz in Deutschland";
    incidence_data = ger_incidences.map((i) => i.avg_incidence);
    infection_data = ger_incidences.map((i) => i.infections);
    last_detail_selector = "";
  } else {
    incidence_data = bl_incidences
      .filter((i) => i.BID == detail_selector)
      .map((i) => i.avg_incidence);
    infection_data = bl_incidences
      .filter((i) => i.BID == detail_selector)
      .map((i) => i.infections);
    r_value_detail_data = bl_incidences
      .filter((i) => i.BID == detail_selector)
      .map((i) => i.r_value);
    infection_detail_layout.title.text =
      "Neuinfektionen in " + bundeslaender[Number(detail_selector) - 1];
    incidence_detail_layout.title.text =
      "7-Tage Inzidenz in " + bundeslaender[Number(detail_selector) - 1];
    r_value_detail_layout.title.text =
      "R-Wert in " + bundeslaender[Number(detail_selector) - 1];
    last_detail_selector = detail_selector;
  }
  //set yaxis range to 110% percent of highest value
  incidence_detail_layout.yaxis.range = [0, Math.max(...incidence_data) * 1.1];
  infection_detail_layout.yaxis.range = [0, Math.max(...infection_data) * 1.1];
  //chart lines
  let incidence_detail_line = {
    x: incidence_dates,
    y: incidence_data,
    type: "line",
    name: "Inzidenz",
    yaxis: "y",
    xaxis: "x",
    fill: "tozeroy",
    fillcolor: "rgba(158,61,112,0.25)",
    line: { color: "rgb(158,61,112)", shape: "spline" },
    showlegend: false,
  };
  let r_value_detail_line = {
    x: incidence_dates,
    y: r_value_detail_data,
    type: "line",
    name: "R-Wert",
    yaxis: "y",
    xaxis: "x",
    fill: "tozeroy",
    fillcolor: "rgba(252,108,5,0.25)",
    line: { color: "rgb(252,108,5)", shape: "spline" },
    showlegend: false,
  };
  let infection_detail_line = {
    x: incidence_dates,
    y: infection_data,
    type: "bar",
    name: "Infektionen",
    yaxis: "y",
    xaxis: "x",
    marker: { color: "rgb(91,94,193)", opacity: 0.75 },
    showlegend: false,
  };
  //chart markers
  let incidence_detail_value_marker = {
    x: [incidence_dates[detail_currentday]],
    y: [incidence_data[detail_currentday]],
    type: "scatter",
    yaxis: "y",
    mode: "markers",
    marker: { symbol: "circle", color: "red", size: 10 },
    showlegend: false,
    hoverinfo: "skip",
  };
  let r_detail_value_marker = {
    x: [incidence_dates[detail_currentday]],
    y: [r_value_detail_data[detail_currentday]],
    type: "scatter",
    yaxis: "y",
    mode: "markers",
    marker: { symbol: "circle", color: "red", size: 10 },
    showlegend: false,
    hoverinfo: "skip",
  };
  let infection_detail_value_marker = {
    x: [incidence_dates[detail_currentday]],
    y: [infection_data[detail_currentday]],
    type: "bar",
    yaxis: "y",
    mode: "markers",
    marker: { color: "red" },
    showlegend: false,
    hoverinfo: "skip",
  };
  //draw charts
  Plotly.react(
    "incidence-detail-chart",
    [incidence_detail_line, incidence_detail_value_marker],
    incidence_detail_layout,
    config
  );
  Plotly.react(
    "r-value-detail-chart",
    [r_value_detail_line, r_detail_value_marker],
    r_value_detail_layout,
    config
  );
  Plotly.react(
    "infection-detail-chart",
    [infection_detail_line, infection_detail_value_marker],
    infection_detail_layout,
    config
  );
}

//draw detail_charts for first time with germany as selection
detail_chart_draw("");

incidence_date_picker.onchange = () => {
  incidence_draw(incidence_date_picker.value);
};

incidence_date_slider.onchange = () => {
  //convert UNIX-time to string
  let day = new Date(incidence_date_slider.valueAsNumber)
    .toISOString()
    .split("T")[0];
  incidence_date_picker.value = day;
  incidence_draw(day);
};

//animation
let animate_bool = false;
async function animate() {
  //start from current day and stop at last date
  let start = incidence_date_slider.valueAsNumber;
  let stop = Number(incidence_date_slider.max);
  //iterate through days and draw each
  for (let i = start; i <= stop; i = i + day_number) {
    if (animate_bool == false) {
      break;
    }
    let day2 = new Date(i).toISOString().split("T")[0];
    incidence_date_picker.value = day2;
    incidence_draw(day2);
    await sleep(50);
  }
  animate_bool = false;
  start_button.innerText = "Animation starten";
}

start_button.onclick = () => {
  //start and stop animation with same button
  if (animate_bool) {
    start_button.innerText = "Animation starten";
    animate_bool = false;
  } else {
    start_button.innerText = "Animation stoppen";
    animate_bool = true;
    animate();
  }
};

//draw for first time
incidence_map_layout.mapbox.zoom = get_zoom();
incidence_draw(incidence_date_picker.value);
