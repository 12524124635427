//convert date string to UNIX-time
export function toUNIXTime(day: string) {
  return new Date(day).getTime();
}
//add leading zero if missing on BID
export function two(key: string) {
  if (key.length < 2) {
    return "0" + key;
  }
  return key;
}
//convert date string to EU date format (dd.mm.yyyy)
export function toEUDate(date: string) {
  var str = new Date(date);
  return (
    two(str.getDate().toString()) +
    "." +
    two((str.getMonth() + 1).toString()) +
    "." +
    str.getFullYear()
  );
}

//value of a day in UnixTime
export const day_number = 86400000; 
//state names in order of BID
export const bundeslaender = [
  "Schleswig-Holstein",
  "Hamburg",
  "Niedersachsen",
  "Bremen",
  "Nordrhein-Westfalen",
  "Hessen",
  "Rheinland-Pfalz",
  "Baden-Württemberg",
  "Bayern",
  "Saarland",
  "Berlin",
  "Brandenburg",
  "Mecklenburg-Vorpommern",
  "Sachsen",
  "Sachsen-Anhalt",
  "Thüringen",
];
//sleep function for animation
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
//colorscheme of contact map
export const contact_color = [
  ["0.0", "#d2d1ff"],
  ["0.2", "#899feb"],
  ["0.4", "#4777e5"],
  ["0.6", "#1265f8"],
  ["0.8", "#2539c9"],
  ["1.0", "#5d0077"],
];
//colorscheme of r_value map
export const ampel = [
  ["0.0", "#D0DECD"],
  ["0.25", "#C0E4B8"],
  ["0.45", "#A4D898"],
  ["0.55", "#E48500"],
  ["1.0", "#A70000"],
];
//colorscheme of incidence map
export const colorscale = [
  ["0.0", "rgb(255,252,205)"],
  ["0.016666667", "rgb(255, 243, 128)"],
  ["0.033333333", "rgb(255, 181, 52)"],
  ["0.066666667", "rgb(212, 54, 36)"],
  ["0.166666667", "rgb(149, 18, 20)"],
  ["0.333333333", "rgb(103, 18, 18)"],
  ["0.666666667", "rgb(221, 0, 133)"],
  ["1.0", "rgb(122, 0, 119)"],
];
//plotly config
export let config = {
  responsive: true,
  displaylogo: false,
  doubleClick: "reset",
  scrollZoom: false,
  modeBarButtonsToRemove: ["autoscale", "pan2d", "select2d", "lasso2d"],
  dragmode: true,
};
//calculate zoom of maps depending on site width and map height
export function get_zoom() {
  const wrapper = document.getElementById("wrapper") as HTMLDivElement;
  const map_div = document.getElementById("r-value-map");
  let map_height = map_div.offsetHeight - 30;
  let wrapper_width = wrapper.offsetWidth * 0.48;
  let zoom_lat = 15.5 - Math.log2(876000 / map_height);
  let zoom_lon = 15.2 - Math.log2(640000 / wrapper_width);
  return Math.min(zoom_lat, zoom_lon);
}
//rolling window average for smoothing of r_value and cx charts
export function smooth(data: number[], window: number) {
  let smooth_data = new Array(data.length);
  window = 7 * window;
  let sum = 0;
  let n = 0;
  for (let i = 0; i < data.length; i++) {
    sum = 0;
    n = 0;
    for (; n < window; n++) {
      if (i - n >= 0) {
        sum += data[i - n];
      } else {
        break;
      }
    }
    smooth_data[i] = sum / n;
  }
  return smooth_data;
}
